import React from "react";

function Portfolio() {
    return (
        <div>
        <h1>Portfolio</h1>
        <p>Website in construction</p>
        </div>
    );
}

export default Portfolio;